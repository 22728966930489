import ClayPanel from "@clayui/panel";
import { useEffect, useState } from "react";
import { getFaqTopics } from "../../services/faq";
import { useTranslation } from "react-i18next";

const Faq = () => {
	const {
		t,
		i18n: { language },
	} = useTranslation();
	const [faqTopics, setFaqTopics] = useState([]);

	useEffect(() => {
		getFaqTopics(language).then(response => {
			setFaqTopics(response);
		});
	}, []);


	return (
		<div className='lms-faq'>
			<h1 className='component-heading lms-faq__title mb-3 text-break'>
				{t("frequently-asked-questions")}
			</h1>
  
			{faqTopics.map((faqTopic) => {
				return (
					<section className='lms-faq__topic' key={faqTopic.id}>
						<h3 className='lms-faq__topic-title mb-3 mt-2'>{faqTopic?.title}</h3>

						{faqTopic?.questions.map((faqTopicQuestion) => {
							return (
								<ClayPanel
                  key={faqTopicQuestion.id}
									collapsable
									collapseClassNames='question-answer'
									collapseHeaderClassNames='question-header'
                  className="lms-faq__topic-question"
									displayTitle={<ClayPanel.Title className="question-header__title">{faqTopicQuestion.title}</ClayPanel.Title>}
									displayType='secondary'
									showCollapseIcon={true}
								>
									<ClayPanel.Body dangerouslySetInnerHTML={{ __html: faqTopicQuestion.answer }} />
								</ClayPanel>
							);
						})}
					</section>
				);
			})}
		</div>
	);
};

export default Faq;
