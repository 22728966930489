import {forwardRef, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import ClayLoadingIndicator from "@clayui/loading-indicator";
import {getCourseFirstLesson} from "../../services/menu";

const CourseFirstLesson = forwardRef((props, ref) => {

    const {assetId,linkTitle} = props;

    const [lessonOne, setLessonOne] = useState(null);

    const [isLoading, setIsLoading] = useState(true);

    const {t, i18n: {changeLanguage, language}} = useTranslation();

    useEffect(() => {

        setIsLoading(true);

        getCourseFirstLesson(assetId).then(result => {

            setLessonOne(result);

            setIsLoading(false);

        })

    }, []);

    return (<>
        {isLoading && (
            <span style={{display: 'inline-block'}}>
                <ClayLoadingIndicator displayType="secondary" size="sm"/>
            </span>

        )}
        {!isLoading && lessonOne && (
            <a href={`/l/${lessonOne.id}`}>{linkTitle}</a>
        )}
    </>)

});

export default CourseFirstLesson;
