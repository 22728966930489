/*global Liferay*/
import {
  convertMinutesToDuration,
  fetchLiferayAsJSON,
  getCourseFirstLessonId,
  isSignedIn,
  getPersonas,
  getTooltipPersona,
} from "../../utils/util";
import { forwardRef, useEffect, useState } from "react";
import "../../index.scss";
import "../../styles/LearningPathSteps.scss";
import { useTranslation } from "react-i18next";
import {
  enrollToLearningPath,
  getUserEnrollmentByLearningPathId,
  updateCompletedSteps,
} from "../../services/enrollment";
import ClayIcon from "@clayui/icon";
import { ClayTooltipProvider } from "@clayui/tooltip";

const LearningPathSteps = forwardRef((props, ref) => {
  const learningPathId = Liferay.ThemeDisplay.getLayoutURL().split("/").pop();
  const [learningPathSteps, setLearningPathSteps] = useState(null);
  const [learningPath, setLearningPath] = useState(null);
  const [isEnrolled, setIsEnrolled] = useState(false);
  const [userEnrollmentId, setUserEnrollmentId] = useState(null);
  const [completedStepsIds, setCompletedStepsIds] = useState("");

  const {
    t,
    i18n: { changeLanguage },
  } = useTranslation();

  useEffect(() => {
    const fetchLearningPathData = async () => {
      try {
        const learningPathResponse = await fetchLiferayAsJSON(
          `/o/c/learningpaths/${learningPathId}`
        );

        const learningPathStepsResponse = await fetchLiferayAsJSON(
          `/o/c/learningpaths/${learningPathId}?nestedFields=learningPathSteps`
        );

        const stepsData = await Promise.all(
          learningPathStepsResponse.learningPathSteps.map(async (step) => {
            const stepResponse = await fetchLiferayAsJSON(
              `/o/c/learningpathsteps/${step.id}?nestedFields=${
                step.type.key === "course" ? "stepCourses" : "externalMedia"
              }`
            );

            if (step.type.key !== "course") {
              return {
                ...step,
                totalDuration: stepResponse.externalMedia[0].durationMinutes,
                url: stepResponse.externalMedia[0].id,
              };
            }

            return {
              ...step,
              totalDuration: stepResponse.stepCourses[0].durationMinutes,
              url: await getCourseFirstLessonId(stepResponse.stepCourses[0].id),
            };
          })
        );

        setLearningPathSteps(stepsData);
        setLearningPath(learningPathResponse);
      } catch (error) {
        console.error("Error fetching learning path:", error);
      }
    };

    fetchLearningPathData();
  }, [learningPathId]);

  const totalDurationLearningPath = learningPathSteps?.reduce((acc, step) => {
    return acc + parseFloat(convertMinutesToDuration(step.totalDuration));
  }, 0);

  useEffect(() => {
    if (learningPathId) {
      const fetchUserEnrollment = async () => {
        const result = await getUserEnrollmentByLearningPathId(learningPathId);

        setUserEnrollmentId(
          result?.items?.length > 0 ? result.items[0].id : ""
        );
        setCompletedStepsIds(
          result?.items?.length > 0 ? result.items[0].completedStepsIds : []
        );
      };

      fetchUserEnrollment();
    }
  }, [learningPathId]);

  useEffect(() => {
    if (userEnrollmentId) {
      setIsEnrolled(true);
    }
  }, [userEnrollmentId, learningPathId]);

  const navigateToStepContent = (step) => {
    const url = `/l/${step.url}`;

    window.location.href = url;
  };

  const handleRegisterAndCompleteStep = async (step) => {
    const stepId = step.id;

    if (step.type.key === "video") {
      if (isSignedIn() && !isEnrolled) {
        await enrollToLearningPath(learningPathId, stepId);
      } else {
        const isStepCompleted = completedStepsIds?.includes(stepId);

        if (isEnrolled && !isStepCompleted) {
          await updateCompletedSteps(
            userEnrollmentId,
            completedStepsIds,
            stepId
          );
        }
      }
    }

    navigateToStepContent(step);
  };

  const handleStepClick = async (step) => {
    handleRegisterAndCompleteStep(step);
  };

  return (
    <>
      <div className="learning-path-banner d-flex justify-content-between">
        <div className="lp-banner-contents p-4">
          <div className="learning-path-tag">
            <p>{t("learning-path")}</p>
          </div>

          <h1>{learningPath?.name}</h1>

          <div className="lp-banner-duration-persona">
            <div className="lp-banner-info-tag d-flex mt-3">
              <p className="lp-banner-duration">
                {[totalDurationLearningPath, " hours"]}
              </p>
            </div>

            <div className="lp-banner-info-tag d-flex mt-3">
              {learningPath && (
                <ClayTooltipProvider>
                  <div
                    className="lp-banner-persona"
                    data-tool-tip-align="top"
                    title={getTooltipPersona(learningPath.persona)}
                  >
                    <p></p>
                    {getPersonas(learningPath.persona)}
                  </div>
                </ClayTooltipProvider>
              )}
            </div>
          </div>
        </div>
      </div>

      <p className="lp-banner-description">{learningPath?.description}</p>

      {learningPathSteps?.map((step, index) => {
        return (
          <div key={index}>
            <div
              className={`align-items-center card-banner d-flex ${step.type.key}`}
            >
              <span>Step {index + 1}</span>
            </div>
            <div
              className="learning-path-step"
              onClick={() => handleStepClick(step)}
            >
              <div
                className="col col-lg-12 col-sm-12 col-12 col-md-12 d-flex flex-column learn-education-card step-card"
                key={step.id}
              >
                <h3>{step.title}</h3>
                <div>{step.description}</div>
                <div className="d-flex card-tags">
                  <div className="banner-tags card-tag d-flex mt-3">
                    <div className="banner-tag card-tag__time">
                      <p>
                        {convertMinutesToDuration(step.totalDuration, "hours")}
                      </p>
                    </div>
                  </div>
                  <div className="ml-2">
                    {completedStepsIds?.includes(step.id) ? (
                      <p className="align-self-start completed-tag">
                        Completed
                        <ClayIcon className="ml-2" symbol="check" />
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }) || <p>No learning path available.</p>}
    </>
  );
});

export default LearningPathSteps;
