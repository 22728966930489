/* global Liferay */

import { request } from "../utils/request";
import { config } from "../utils/constants";
import { getCurrentSiteId, getCurrentUserId } from "../utils/util";

export const enrollToCourse = (courseId) => {
  return request({
    url: `${config.enrollmentEndpoint}scopes/${getCurrentSiteId()}`,
    method: "post",
    data: {
      active: true,
      r_courseEnrollment_c_courseId: courseId,
      r_userenrollments_userId: getCurrentUserId(),
    },
  });
};

export const enrollToCourseAndCompleteAsset = (courseId, assetId) => {
  return request({
    url: `${config.enrollmentEndpoint}scopes/${getCurrentSiteId()}`,
    method: "post",
    data: {
      active: true,
      completedAssetIds: assetId,
      r_courseEnrollment_c_courseId: courseId,
      r_userenrollments_userId: getCurrentUserId(),
    },
  });
};

export const enrollToLearningPath = (learningPathId) => {
  return request({
    url: `${config.enrollmentEndpoint}scopes/${getCurrentSiteId()}`,
    method: "post",
    data: {
      r_learningPathEnrollment_c_learningPathId: learningPathId,
      r_userenrollments_userId: getCurrentUserId(),
    },
  });
};

export const getAttachmentsByLessonId = (lessonId) => {
  return request({
    url: `${config.lessonEndpoint}${lessonId}/attachment?page=0`,
    method: "get",
  });
};

export const getCourseByAssetTypeAndId = async (assetType, assetId) => {
  const response = await Liferay.Util.fetch(
    `/o/c/modules/scopes/${getCurrentSiteId()}?fields=r_module_c_courseId&filter=${assetType}/id eq '${assetId}'`
  );

  const data = await response.json();

  if (data.items.length > 0) {
    return data.items[0].r_module_c_courseId;
  }

  return null;
};

export const getEnrollmentsByCourseId = (courseId) => {
  let parsedId = isNaN(parseInt(courseId)) ? -1 : parseInt(courseId);

  return request({
    url: `${
      config.enrollmentEndpoint
    }scopes/${getCurrentSiteId()}?filter=r_courseEnrollment_c_courseId eq '${parsedId}' and r_userenrollments_userId eq '${getCurrentUserId()}'`,
  });
};

export const getEnrollmentsByLearningPathId = (learningPathId) => {
  let parsedId = isNaN(parseInt(learningPathId))
    ? -1
    : parseInt(learningPathId);

  return request({
    url: `${
      config.enrollmentEndpoint
    }scopes/${getCurrentSiteId()}?filter=r_learningPathEnrollment_c_learningPathId eq '${parsedId}' and r_userenrollments_userId eq '${getCurrentUserId()}'`,
  });
};

export const getExercisesByModuleId = (moduleId) => {
  return request({
    url: `${config.moduleEndpoint}${moduleId}/exercise?page=0`,
    method: "get",
  });
};

export const getFirstLessonIdByCourseId = async (courseId) => {
  const data = await request({
    url: `${
      config.moduleEndpoint
    }scopes/${getCurrentSiteId()}?filter=r_module_c_courseId eq '${courseId}' and position eq 0&nestedFields=lesson`,
    method: "get",
  });

  return data.items[0].lesson[0].id;
};

export const getLessonById = (lessonId) => {
  return request({
    url: `${config.lessonEndpoint}${lessonId}`,
    method: "get",
  });
};

export const getLessonByModuleId = (moduleId) => {
  return request({
    url: `${config.moduleEndpoint}${moduleId}/lesson?page=0&pageSize=100`,
    method: "get",
  });
};

export const getModulesByCourseId = (courseId) => {
  return request({
    url: `${config.courseEndpoint}${courseId}/module?page=0`,
    method: "get",
  });
};

export const getSortedCourses = async () => {
  const data = await request({
    url: `${config.courseEndpoint}scopes/${getCurrentSiteId()}?pageSize=-1&sort=position:asc`,
    method: "get",
  });
  return data.items;
};

export const getAllCoursesAndFirstLessons = async () => {
  const data = await request({
    url: `${
      config.moduleEndpoint
    }scopes/${getCurrentSiteId()}?filter=position eq 0&nestedFields=course,lesson&pageSize=-1`,
    method: "get",
  });
  return data.items;
};

export const getCourseInfoByCourseId = async (courseId) => {
  const data = await request({
    url: `${config.courseEndpoint}${courseId}`,
    method: "get",
  })
  return data;
}