import {
  enrollToCourse,
  enrollToLearningPath,
} from "../services/course";
import { getAssetDuration } from "../services/duration";
import {
  getCourseFirstLesson,
  getCoursesPaged,
  getLearningPathBreakdown,
  getLearningPathStepBreakdown,
  getNavigationMenu,
} from "../services/menu";
import { getStepInfo } from "../services/learning-path-step";
import {
  getUserBadges,
  getUserEnrollmentByAssetId,
  getUserEnrollmentById,
  getUserEnrollments,
} from "../services/user";

window.LiferayLMS = {};
window.LiferayLMS.enrollToCourseAsync = enrollToCourse;
window.LiferayLMS.enrollToLearningPathAsync = enrollToLearningPath;
window.LiferayLMS.getAssetDurationAsync = getAssetDuration;
window.LiferayLMS.getCourseFirstLessonAsync = getCourseFirstLesson;
window.LiferayLMS.getCoursesPagedAsync = getCoursesPaged;
window.LiferayLMS.getLearningPathBreakdownAsync = getLearningPathBreakdown;
window.LiferayLMS.getLearningPathStepBreakdownAsync =
  getLearningPathStepBreakdown;
window.LiferayLMS.getNavigationMenuAsync = getNavigationMenu;
window.LiferayLMS.getStepInfoAsync = getStepInfo;
window.LiferayLMS.getUserBadgesAsync = getUserBadges;
window.LiferayLMS.getUserEnrollmentByAssetIdAsync = getUserEnrollmentByAssetId;
window.LiferayLMS.getUserEnrollmentByIdAsync = getUserEnrollmentById;
window.LiferayLMS.getUserEnrollmentsAsync = getUserEnrollments;
