import useLinkedInProfile from "../../Hooks/UseLinkedInButton";

const UserDashboardBadgeCard = ({ userBadges }) => {
  const { profileUrls } = useLinkedInProfile();

  return (
    <div className="section-cards">
      {userBadges.map((badge, index) => (
        <div
          className="d-flex flex-column h-100 justify-content-between"
          key={index}
        >
          <div className="badge-card">
            <div>
              <div className="badge-img-container mb-3">
                <img alt={badge.title} src={badge.image}></img>
              </div>
              <h3>{badge.title}</h3>
            </div>
            <p>{badge.year}</p>
            <div>
              {profileUrls[index] && (
                <a
                  className="button-linkedin-add"
                  href={profileUrls[index]}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Add to LinkedIn
                </a>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default UserDashboardBadgeCard;
