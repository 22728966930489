/* global Liferay */

import { forwardRef, useEffect, useState } from "react";
import ClayIcon from "@clayui/icon";
import { getNextLessonLocalStorage, getPreviousLessonLocalStorage, getSignInPath, isSignedIn } from "../../utils/util";
import { useTranslation } from "react-i18next";
import { enrollToCourseAndCompleteAsset, getCourseByAssetTypeAndId } from "../../services/course";
import {
  getUserEnrollmentByCourseId,
  updateCompletedAssets,
  updateUserEnrollmentByEnrollmentId,
} from "../../services/enrollment";

const LessonNavigation = forwardRef((props, ref) => {
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  const { linkPrefix } = props;

  const [nextLesson, setNextLesson] = useState(null);
  const [prevLesson, setPrevLesson] = useState(null);
  const [courseId, setCourseId] = useState(null);
  const [isEnrolled, setIsEnrolled] = useState(false);
  const [userEnrollmentId, setUserEnrollmentId] = useState(null);
  const [completedAssetIds, setCompletedAssetsIds] = useState("");

  const currentLessonId = Liferay.ThemeDisplay.getLayoutURL().split("/").pop();

  useEffect(() => {
    Liferay.once("lessonNavigationUpdated", (event) => {
      setNextLesson(getNextLessonLocalStorage(currentLessonId))
      setPrevLesson(getPreviousLessonLocalStorage(currentLessonId))
    });
    
  }, [currentLessonId, nextLesson, prevLesson]);

  useEffect(() => {
    const fetchCourseId = async () => {
      const response = await getCourseByAssetTypeAndId('lesson', currentLessonId);
      setCourseId(response);
    };

    fetchCourseId();
  }, [currentLessonId]);

  useEffect(() => {
    if (courseId) {
      const fetchUserEnrollment = async () => {
        const result = await getUserEnrollmentByCourseId(courseId);

        setUserEnrollmentId(result?.items?.length > 0 ? result.items[0].id : "");
        setCompletedAssetsIds(result?.items?.length > 0 ? result.items[0].completedAssetIds : []);
      };

      fetchUserEnrollment();
    }
  }, [courseId]);

  useEffect(() => {
    if (userEnrollmentId) {
      setIsEnrolled(true);
    }
  }, [userEnrollmentId, courseId]);

  const handleRegisterAndUpdateCompleteAssets = async () => {
    if (!isEnrolled) {
      await enrollToCourseAndCompleteAsset(courseId, currentLessonId);
    }
  };

  const navigateToLesson = (lessonId) => {
    const url = `${linkPrefix && linkPrefix.length > 0 ? linkPrefix : "/l/"}${lessonId
      }`;

    window.location.href = url;
  };

  const handleUpdateCompletedAssets = async () => {
    const isLessonCompleted = completedAssetIds?.includes(currentLessonId)

    if (isEnrolled && !isLessonCompleted) {
      await updateCompletedAssets(userEnrollmentId, completedAssetIds, currentLessonId);
    }

    updateUserEnrollmentByEnrollmentId(userEnrollmentId, true)
  };

  const handleClickCompleteButton = async () => {

    if (isEnrolled) {
      await handleUpdateCompletedAssets();
    } else {
      if (isSignedIn()) {
        await handleRegisterAndUpdateCompleteAssets();
      }
    }

    if (nextLesson) {
      navigateToLesson(nextLesson.id);
    } else {
      window.location.href = "https://learn.liferay.com/education/index";
    }
  };

  return (
    <div className="lesson-navigation-sign-in-container">
      <div onClick={handleClickCompleteButton}>
        <div className="course-nav-bottom__banner">
          <div className="banner-options z-2">
            {nextLesson && nextLesson.id ? (
              <>
                <div className="banner-option-text-alignment">
                  <div className="banner-next-container mr-1">
                    <ClayIcon className="mr-2" symbol="check-circle" />
                    {t("mark-as-complete-and-continue")}
                  </div>
                  <div className="banner-title">{nextLesson.title === "" ? nextLesson.title_i18n.en_US : nextLesson.title}</div>
                </div>
                <div className="banner-icon">
                  <ClayIcon symbol="order-arrow-right"></ClayIcon>
                </div>
              </>
            )
              : (
                <>
                  <div className="banner-next-container mr-1">
                    <ClayIcon className="mr-2" symbol="check-circle" />
                    {t("mark-as-complete-and-return-home")}
                  </div>
                </>
              )
            }
          </div>
          <div className="course-nav-bottom__banner-img">
            <svg width="172" height="64" viewBox="0 0 172 64" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_6415_5067)">
                <path d="M42.6965 27.1664C-8.45351 79.5522 -21.385 159.021 40.3186 224.387C102.022 289.753 176.482 252.453 225.073 202.688C276.223 150.302 272.435 68.4778 219.858 17.1402C167.281 -34.1975 93.8465 -25.2195 42.6965 27.1664Z" fill="url(#paint0_linear_6415_5067)" />
                <path d="M262.169 25.1313C198.272 -71.3646 77.6534 -38.2028 49.0372 18.101C19.2401 76.7342 53.9354 219.304 129.907 265.536C223.821 322.688 326.172 121.782 262.169 25.1281V25.1313Z" fill="url(#paint1_linear_6415_5067)" />
              </g>
              <defs>
                <linearGradient id="paint0_linear_6415_5067" x1="-4.43851" y1="-23.4822" x2="136.937" y2="127.991" gradientUnits="userSpaceOnUse">
                  <stop stop-color="white" stop-opacity="0.7" />
                  <stop offset="0.11" stop-color="white" stop-opacity="0.55" />
                  <stop offset="0.25" stop-color="white" stop-opacity="0.38" />
                  <stop offset="0.39" stop-color="white" stop-opacity="0.24" />
                  <stop offset="0.53" stop-color="white" stop-opacity="0.14" />
                  <stop offset="0.67" stop-color="white" stop-opacity="0.06" />
                  <stop offset="0.81" stop-color="white" stop-opacity="0.01" />
                  <stop offset="0.96" stop-color="white" stop-opacity="0" />
                </linearGradient>
                <linearGradient id="paint1_linear_6415_5067" x1="56.5916" y1="-127.413" x2="239.622" y2="269.15" gradientUnits="userSpaceOnUse">
                  <stop stop-color="white" stop-opacity="0.25" />
                  <stop offset="0.52" stop-color="white" stop-opacity="0.08" />
                  <stop offset="0.81" stop-color="white" stop-opacity="0" />
                </linearGradient>
                <clipPath id="clip0_6415_5067">
                  <rect width="220" height="220" fill="white" transform="translate(0 -78)" />
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>
      </div>

      {prevLesson ? (
        <div className="course-nav-bottom__banner-line course-nav-bottom__menu d-flex">
          <div className="d-flex menu-previous-lesson" onClick={() => { navigateToLesson(prevLesson.id) }}>
            <div className="previous-lesson-icon">
              <ClayIcon symbol="order-arrow-left"></ClayIcon>
            </div>
            <div className="previous-lesson-title">{prevLesson.title === "" ? prevLesson.title_i18n.en_US : prevLesson.title}</div>
          </div>
          {!isSignedIn() && (
            <div className="sign-in-text">
              <a href={getSignInPath()}>{t("sign-in")}</a>&nbsp;
              {t("to-save-your-progress")}
            </div>
          )}
        </div>
      ) :
        <>
          {!isSignedIn() && (
            <div className="course-nav-bottom__banner-line sign-in-text">
              <a href={getSignInPath()}>{t("sign-in")}</a>&nbsp;
              {t("to-save-your-progress")}
            </div>
          )}
        </>
      }
    </div>
  );
});

export default LessonNavigation;