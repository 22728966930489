import { request } from "../utils/request";
import { config } from "../utils/constants";

const faqFields = [
  "id",
	"title_i18n",
  "p2s3TopicToP2S3Questions.id",
	"p2s3TopicToP2S3Questions.title_i18n",
	"p2s3TopicToP2S3Questions.answer_i18n",
	"p2s3TopicToP2S3Questions.position",
];

export const getFaqTopics = async language => {
	return request({
		url: `${config.faqTopicsEndpoint}/scopes/guest?fields=${faqFields.join(",")}&nestedFields=p2s3TopicToP2S3Questions&pageSize=0&sort=position:asc`,
		method: "get",
	}).then(data => {
		const currentLanguage = getLanguageAttributeKey(data.items[0].title_i18n, language);

		return data.items.map(faqTopic => {
			const sortedQuestions = sortTopicQuestions(faqTopic?.p2s3TopicToP2S3Questions);

			return {
        id: faqTopic?.id,
				title: faqTopic?.title_i18n[currentLanguage],
				questions: simplifyQuestionsStructure(sortedQuestions, currentLanguage),
			};
		});
	});
};

function simplifyQuestionsStructure(questions, currentLanguage) {
	return questions.map(current_question => ({
    id: current_question?.id,
		title: current_question?.title_i18n[currentLanguage],
		answer: current_question?.answer_i18n[currentLanguage],
	}));
}

function sortTopicQuestions(questions) {
	return questions.sort(
		(questionA, questionB) => questionA?.position - questionB?.position
	);
}

function getLanguageAttributeKey(attributeKey, language) {
	return Object.keys(attributeKey).filter(key => key.includes(language));
}

