import React from "react";
import { enrollToCourseAndCompleteAsset } from "../../services/course";
import { updateCompletedAssets } from "../../services/enrollment";
import { getSignInPath, isSignedIn } from "../../utils/util";
import ClayIcon from "@clayui/icon";
import { t } from "i18next";

function CourseBottomNavigation({
  isEnrolled,
  courseId,
  currentQuizzId,
  completedAssetIds,
  linkPrefix,
  userEnrollmentId,
  prevLesson,
  nextLesson,
}) {
  const handleRegisterAndUpdateCompleteAssets = async () => {
    if (!isEnrolled) {
      await enrollToCourseAndCompleteAsset(courseId, currentQuizzId);
    }
  };

  const navigateToLesson = (lessonId) => {
    const url = `${linkPrefix && linkPrefix.length > 0 ? linkPrefix : "/l/"
      }${lessonId}`;

    window.location.href = url;
  };

  const handleUpdateCompletedAssets = async () => {
    const isLessonCompleted = completedAssetIds?.includes(currentQuizzId);

    if (isEnrolled && !isLessonCompleted) {
      await updateCompletedAssets(
        userEnrollmentId,
        completedAssetIds,
        currentQuizzId
      );
    }
  };

  const handleClickCompleteButton = async () => {
    if (isEnrolled) {
      await handleUpdateCompletedAssets();
    } else {
      if (isSignedIn()) {
        await handleRegisterAndUpdateCompleteAssets();
      }
    }

    if (nextLesson) {
      navigateToLesson(nextLesson.id);
    } else {
      window.location.href = "https://learn.liferay.com/education/index";
    }
  };

  return (
		<>
			<div className="lesson-navigation-sign-in-container">
				<div>
					<div>
						<div
							className="course-nav-bottom__banner"
							onClick={handleClickCompleteButton}
						>
							<div className="banner-options z-2">
								{nextLesson && nextLesson.id ? (
									<>
										<div className="banner-option-text-alignment">
											<div className="banner-next-container mr-1">
												<ClayIcon className="mr-2" symbol="check-circle" />
												{t("mark-as-complete-and-continue")}
											</div>
											<div className="banner-title">{nextLesson.title}</div>
										</div>
										<div className="banner-icon">
											<ClayIcon symbol="order-arrow-right"></ClayIcon>
										</div>
									</>
								) : (
									<div className="banner-next-container mr-1">
										<ClayIcon className="mr-2" symbol="check-circle" />
										{t("mark-as-complete-and-return-home")}
									</div>
								)}
							</div>
							<div className="course-nav-bottom__banner-img">
								<svg
									width="172"
									height="64"
									viewBox="0 0 172 64"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<g clip-path="url(#clip0_6415_5067)">
										<path
											d="M42.6965 27.1664C-8.45351 79.5522 -21.385 159.021 40.3186 224.387C102.022 289.753 176.482 252.453 225.073 202.688C276.223 150.302 272.435 68.4778 219.858 17.1402C167.281 -34.1975 93.8465 -25.2195 42.6965 27.1664Z"
											fill="url(#paint0_linear_6415_5067)"
										/>
										<path
											d="M262.169 25.1313C198.272 -71.3646 77.6534 -38.2028 49.0372 18.101C19.2401 76.7342 53.9354 219.304 129.907 265.536C223.821 322.688 326.172 121.782 262.169 25.1281V25.1313Z"
											fill="url(#paint1_linear_6415_5067)"
										/>
									</g>
									<defs>
										<linearGradient
											id="paint0_linear_6415_5067"
											x1="-4.43851"
											y1="-23.4822"
											x2="136.937"
											y2="127.991"
											gradientUnits="userSpaceOnUse"
										>
											<stop stop-color="white" stop-opacity="0.7" />
											<stop
												offset="0.11"
												stop-color="white"
												stop-opacity="0.55"
											/>
											<stop
												offset="0.25"
												stop-color="white"
												stop-opacity="0.38"
											/>
											<stop
												offset="0.39"
												stop-color="white"
												stop-opacity="0.24"
											/>
											<stop
												offset="0.53"
												stop-color="white"
												stop-opacity="0.14"
											/>
											<stop
												offset="0.67"
												stop-color="white"
												stop-opacity="0.06"
											/>
											<stop
												offset="0.81"
												stop-color="white"
												stop-opacity="0.01"
											/>
											<stop offset="0.96" stop-color="white" stop-opacity="0" />
										</linearGradient>
										<linearGradient
											id="paint1_linear_6415_5067"
											x1="56.5916"
											y1="-127.413"
											x2="239.622"
											y2="269.15"
											gradientUnits="userSpaceOnUse"
										>
											<stop stop-color="white" stop-opacity="0.25" />
											<stop
												offset="0.52"
												stop-color="white"
												stop-opacity="0.08"
											/>
											<stop offset="0.81" stop-color="white" stop-opacity="0" />
										</linearGradient>
										<clipPath id="clip0_6415_5067">
											<rect
												width="220"
												height="220"
												fill="white"
												transform="translate(0 -78)"
											/>
										</clipPath>
									</defs>
								</svg>
							</div>
						</div>
					</div>

					{prevLesson ? (
						<div className="d-flex course-nav-bottom__banner-line">
							<div
								className="d-flex menu-previous-lesson"
								onClick={() => {
									navigateToLesson(prevLesson.id);
								}}
							>
								<div className="previous-lesson-icon">
									<ClayIcon symbol="order-arrow-left"></ClayIcon>
								</div>
								<div className="previous-lesson-title">{prevLesson.title}</div>
							</div>
							{!isSignedIn() && (
								<div className="sign-in-text">
									<a href={getSignInPath()}>{t("sign-in")}</a>&nbsp;
									{t("to-save-your-progress")}
								</div>
							)}
						</div>
					) : (
						<>
							{!isSignedIn() && (
								<div className="course-nav-bottom__banner-line sign-in-text">
									<a href={getSignInPath()}>{t("sign-in")}</a>&nbsp;
									{t("to-save-your-progress")}
								</div>
							)}
						</>
					)}
				</div>
			</div>
		</>
	);
}

export default CourseBottomNavigation;
